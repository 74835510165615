@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  /* height: 100%; */
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

.pro-sidebar {
  position: fixed;
}
.pro-sidebar.collapsed .pro-sidebar {
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-calendar-picker-indicator {
  filter: invert(344%) sepia(65%) saturate(7299%) hue-rotate(80deg)
    brightness(107%) contrast(117%);
}
.for-report {
  display: none;
}

@media print {
  .not-for-report {
    display: none !important;
  }
  .for-report {
    display: block;
  }
  .table-for-report {
    display: flex;
    flex-direction: column;
  }
  .page-break {
    page-break-before: always; /* or page-break-after: always; */
    margin-top: 50px !important;
  }
  .MuiDataGrid-footerContainer,
  .MuiDataGrid-toolbarContainer {
    display: none !important;
  }
  .css-1kt8ah5-MuiDataGrid-root {
    color: #222222 !important;
    /* font-size: 20px !important; */
  }
  .title-print {
    color: #000;
    font-size: 30px !important;
  }
  .chart-title {
    text-align: center;
    margin-top: 170px !important;
    margin-bottom: 80px !important;
  }
}
